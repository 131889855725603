export const KYC_EXCLUDES = ["address", "contactphone", "country", "email", "firstName", "lastName"];

export interface IdentityRole {
  id: string;
  status: {
    id: string;
    label: string;
  };
  legalType: {
    id: string;
    label: string;
  };
  profileId: string;
  distributorId: string;
  information: string;
  role: {
    id: string;
    label: string;
  };
  creationDate: string;
  updateDate: string;
  lastUpdateKyc: string;
  kyc: object;
}

export interface KycDocument {
  id: string;
  type: string;
}
