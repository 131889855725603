import type { AxiosInstance, AxiosRequestConfig } from "axios";
import axios from "axios";

import type { AuthenticationManager } from "../../domains/authentication/authentication-manager";
import type { ServiceDomainManager } from "../../domains/service-domain/service-domain-manager";
import { Config } from "../config/config";
import type { DebugInterface } from "../debug/debug-interface";
import type { I18NStore } from "../i18n/i18n-store";
import type { HttpService } from "./http-service";
import { addAcceptLanguageInterceptor } from "./interceptors/add-accept-language-interceptor";
import { addAuthorizationInterceptor } from "./interceptors/add-authorization-interceptor";
import { addBaseUrlInterceptor } from "./interceptors/add-base-url-interceptor";
import { addSecuredCookieInterceptor } from "./interceptors/add-secured-cookie-interceptor";
import { addUserAgentInterceptor } from "./interceptors/add-user-agent-interceptor";
import { debugResponseInterceptor } from "./interceptors/debug-response-interceptor";
import { addRequestInterceptor, addResponseInterceptor } from "./interceptors/interceptor";
import { addRequestLogInterceptor } from "./interceptors/log-request-interceptor";
import { logResponseInterceptor } from "./interceptors/log-response-interceptor";
import { renewTokenInterceptor } from "./interceptors/renew-token-interceptor";
import type { SecuredCookiesService } from "./secured-cookies-service";
import type { UserAgentService } from "./user-agent-service";

export class ConnectedServiceDomainApiService {
  public instance: AxiosInstance;

  public constructor(
    private httpService: HttpService | null,
    private customUserAgentService: UserAgentService | null,
    private authManager: ServiceDomainManager | AuthenticationManager,
    private i18NStore: I18NStore,
    private securedCookiesService?: SecuredCookiesService,
    private debugIntercept?: DebugInterface | undefined,
  ) {
    this.instance = axios.create();
    addRequestInterceptor(this.instance, addBaseUrlInterceptor(Config.SERVICE_DOMAIN_API_URL));
    addRequestInterceptor(this.instance, addAcceptLanguageInterceptor(this.i18NStore));
    addRequestInterceptor(this.instance, addAuthorizationInterceptor(this.authManager));
    if (this.httpService) {
      addResponseInterceptor(
        this.instance,
        renewTokenInterceptor(this.httpService, this.authManager, this.securedCookiesService),
      );
    }
    if (this.securedCookiesService) {
      addRequestInterceptor(this.instance, addSecuredCookieInterceptor(this.securedCookiesService));
    }
    if (this.customUserAgentService) {
      addRequestInterceptor(this.instance, addUserAgentInterceptor(this.customUserAgentService));
    }
    addRequestInterceptor(this.instance, addRequestLogInterceptor());
    if (this.debugIntercept) {
      addResponseInterceptor(this.instance, debugResponseInterceptor(debugIntercept));
    }
    addResponseInterceptor(this.instance, logResponseInterceptor);
  }

  public async buildAuthenticatedRequest(url: string | undefined) {
    let request: AxiosRequestConfig = { url };

    const baseUrlInterceptor = addBaseUrlInterceptor(Config.SERVICE_DOMAIN_API_URL);
    if (baseUrlInterceptor.onFulfilled) {
      request = await baseUrlInterceptor.onFulfilled({ url });
    }

    const authorizationInterceptor = addAuthorizationInterceptor(this.authManager);
    if (authorizationInterceptor.onFulfilled) {
      request = await authorizationInterceptor.onFulfilled(request);
    }
    return request;
  }

  public getInstance(): AxiosInstance {
    return this.instance;
  }
}
