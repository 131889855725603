import type { CountryCode } from "libphonenumber-js";
import { AsYouType } from "libphonenumber-js";
import { cloneDeep } from "lodash";
import type { FormEvent } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";

import type { Country } from "../../../../shared/assets/countries";
import { countries, countriesArray } from "../../../../shared/assets/countries";
import { Amount, switchCurrency } from "../../../../shared/core/amount/amount";
import { Config } from "../../../../shared/core/config/config";
import type { Currency, CurrencyIdentifier } from "../../../../shared/core/currency/currency";
import { Currencies } from "../../../../shared/core/currency/currency";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { configurationManager, featuresManager } from "../../../../shared/core/service/services";
import type { Acceptor } from "../../../../shared/domains/acceptors/acceptor";
import type { Account } from "../../../../shared/domains/account/account";
import { getAvailableBalance } from "../../../../shared/domains/account/account";
import { useAccounts } from "../../../../shared/domains/account/use-accounts";
import { useSelectableCurrencies } from "../../../../shared/domains/account/use-selectable-currencies";
import useCurrencies from "../../../../shared/domains/currencies/use-currencies";
import type { Recipient, TransferRecipient } from "../../../../shared/domains/recipients/recipient";
import { TransferMode } from "../../../../shared/domains/transactions/cash-transfer/transfer-mode";
import type {
  AccountOrRecipient,
  PaymentAddress,
  PaymentNetwork,
  PaymentNetworkProduct,
} from "../../../../shared/domains/transactions/customer-instruction";
import { PaymentIdentificationMode } from "../../../../shared/domains/transactions/customer-instruction";
import { getCurrencySelectOptions } from "../../../../shared/domains/transactions/forex-config-options";
import type { RecurringTransferParams } from "../../../../shared/domains/transactions/recurring-transfer";
import { defaultRecurringTransferParams } from "../../../../shared/domains/transactions/recurring-transfer";
import { useCustomerInstruction } from "../../../../shared/domains/transactions/use-customer-instuction";
import useNetworks from "../../../../shared/domains/transactions/use-networks";
import { isDefined } from "../../../../shared/utils/assert";
import { setCountryFromAccount } from "../../../../shared/utils/countries.ts";
import { getCurrencyFromBic, getCurrencyFromIban } from "../../../../shared/utils/iban";
import { useObservable } from "../../../../shared/utils/observable";
import { useAsyncEffect } from "../../../../shared/utils/utils";
import { AccountButton, SourceAndDestinationEnum } from "../../screen/transfer/components/source-and-destination";
import { theme } from "../../styles/theme";
import { AmountText } from "../amount-text";
import { PrimaryButton } from "../buttons/primary-button";
import { ErrorMessage } from "../error-message.tsx";
import { AmountInput } from "../forms/amount-input";
import FormLabel from "../forms/form-label";
import type { PrefixedSelectInputType } from "../forms/prefixed-select-input";
import { PrefixedSelectInput } from "../forms/prefixed-select-input";
import { PrefixContainer, PrefixedTextInput } from "../forms/prefixed-text-input";
import { SelectInput } from "../forms/select-input";
import { TextInput } from "../forms/text-input";
import { Selector } from "../selector";
import { ChevronIcon } from "../svg/chevron";
import RecurringTransferForm from "./recurring-transfer-form";
import { TransferModeSelection } from "./transfer-mode-selection";

interface AmountSelectionProps {
  children?: React.ReactNode;
  className?: string;
  recipient?: Recipient;
  acceptor?: Acceptor;
  loading?: boolean;
  isP2PSimpleTransfer?: boolean;
  paymentNetwork: PaymentNetworkProduct | null;
  onChangePaymentNetwork: (product: PaymentNetworkProduct) => void;
  isOpenLoop?: boolean;
  sourceAccount?: Account | null;
  destinationAccountOrRecipient?: AccountOrRecipient | null;
  editingSourceAndDestination?: SourceAndDestinationEnum | null;
  onOpenSourceAccountSelection?: () => void;
  onClearDestinationAccountOrRecipientSelection?: () => void;
  onOpenDestinationAccountOrRecipientSelection?: (
    needCreditorPhoneNumber?: boolean,
    needCreditorAccountNumber?: boolean,
    canDefineAddress?: boolean,
  ) => void;
  submitAmount: (
    recipient: TransferRecipient | AccountOrRecipient,
    amount: Amount,
    sourceAccounts: Account[],
    description?: string,
    sourceAccount?: Account | null,
    paymentNetwork?: PaymentNetwork | null,
    transferMode?: TransferMode,
    creditorAddress?: PaymentAddress,
    foreignAmount?: Amount | null,
    recurringTransferParams?: RecurringTransferParams,
  ) => void;
}

export const AmountSelection = (props: AmountSelectionProps) => {
  const {
    acceptor,
    children,
    className,
    destinationAccountOrRecipient,
    editingSourceAndDestination,
    isOpenLoop,
    isP2PSimpleTransfer,
    loading,
    paymentNetwork,
    recipient,
    sourceAccount,
    onChangePaymentNetwork,
    onClearDestinationAccountOrRecipientSelection,
    onOpenDestinationAccountOrRecipientSelection,
    onOpenSourceAccountSelection,
    submitAmount,
  } = props;
  const accounts = useAccounts();
  const selectableCurrencies = useSelectableCurrencies(accounts);
  const configuration = useObservable(configurationManager.configuration);
  const defaultCountry = useObservable(configurationManager.defaultCountry);
  const canMakeRecurringTransfer = isOpenLoop && featuresManager.features.get().paymentStandingOrderCreate;
  const [recurringTransferParams, setRecurringTransferParams] = useState<RecurringTransferParams>(
    cloneDeep(defaultRecurringTransferParams),
  );
  const { paymentNetworks, getPaymentNetworks, getPaymentNetworkProduct } = useCustomerInstruction();
  const [selectedPaymentNetwork, setSelectedPaymentNetwork] = useState<PaymentNetwork | null>(null);
  const { canDefineAddress, networkIdentificationModes, isAddressUnstructured, isAddressOptional } = useNetworks(
    paymentNetworks,
    selectedPaymentNetwork,
    true,
  );
  const [selectedCountry, setSelectedCountry] = useState<Country>(countriesArray[0]);
  const [currency, setCurrency] = useState<string | null>(null);
  const [transferMode, setTransferMode] = useState<TransferMode>(TransferMode.AccountTransfer);
  const [amount, setAmount] = useState<Amount | null>(null);
  const [foreignCurrency, setForeignCurrency] = useState<CurrencyIdentifier | string | null>(null);
  const { canShowExchangeRate, convertedAmount } = useCurrencies(
    amount,
    isOpenLoop ? foreignCurrency : Config.CURRENCY_TO_CONVERT_IN_EUR_ENABLED ? "EUR" : null,
  );
  const { formatMessage } = useIntl();
  const [description, setDescription] = useState("");
  const [recipientData, setRecipientData] = useState<Partial<Recipient> | null>(recipient ? { ...recipient } : null);
  const [creditorAddress, setCreditorAddress] = useState<PaymentAddress>({
    addressLines: "",
    structuredAddress: {
      buildingNumber: "",
      streetName: "",
      postCode: "",
      townName: "",
    },
    country: "",
  });
  const [countryCode, setCountryCode] = useState(defaultCountry);
  const [dataError, setDataError] = useState<undefined | string>(undefined);
  const hasError = isDefined(dataError);
  const needCreditorPhoneNumber =
    isOpenLoop &&
    networkIdentificationModes.includes(PaymentIdentificationMode.PHONE) &&
    networkIdentificationModes.length === 1;
  const needCreditorAccountNumber =
    isOpenLoop &&
    networkIdentificationModes.includes(PaymentIdentificationMode.ACCOUNT_NUMBER) &&
    networkIdentificationModes.length === 1;

  const canEditForeignCurrency = destinationAccountOrRecipient && !destinationAccountOrRecipient?.currencyAlpha;

  const { forexConfiguration: { enabled: forexEnabled } = { enabled: false } } = paymentNetwork?.processing || {};
  const [amountError, setAmountError] = useState<string | null>(null);

  useAsyncEffect(async () => {
    if (!isOpenLoop) {
      return;
    }
    getPaymentNetworks();
  }, []);

  useEffect(() => {
    if (!selectedPaymentNetwork && paymentNetworks.length > 0) {
      setSelectedPaymentNetwork(paymentNetworks[0]);
    }
  }, [paymentNetworks]);

  useEffect(() => {
    if (isOpenLoop && selectedPaymentNetwork) {
      onClearDestinationAccountOrRecipientSelection?.();
    }
  }, [selectedPaymentNetwork]);

  useEffect(() => {
    if (!isOpenLoop) {
      let initialCurrency = currency;
      if (accounts.length > 0 && !isDefined(currency)) {
        initialCurrency = accounts[0]?.currencyAlpha;
        setCurrency(initialCurrency);
      }
      if (!isDefined(amount) && isDefined(initialCurrency)) {
        setAmount(new Amount(0, initialCurrency));
      }
    }
  }, [accounts, currency, amount]);

  useEffect(() => {
    if (sourceAccount) {
      const curr = sourceAccount.currencyAlpha;
      setCurrency(curr);
      setAmount(new Amount(0, curr));
    }
  }, [sourceAccount]);

  useEffect(() => {
    if (sourceAccount && !destinationAccountOrRecipient) {
      setForeignCurrency(sourceAccount.currencyAlpha);
    }
  }, [sourceAccount, destinationAccountOrRecipient]);

  useEffect(() => {
    if (isOpenLoop && destinationAccountOrRecipient) {
      if (destinationAccountOrRecipient.currencyAlpha) {
        setForeignCurrency(destinationAccountOrRecipient.currencyAlpha);
      } else if (destinationAccountOrRecipient.iban) {
        const curr = getCurrencyFromIban(destinationAccountOrRecipient.iban);
        setForeignCurrency(curr);
      } else if (destinationAccountOrRecipient.bic) {
        const curr = getCurrencyFromBic(destinationAccountOrRecipient.bic);
        setForeignCurrency(curr);
      } else if (sourceAccount) {
        setForeignCurrency(sourceAccount.currencyAlpha);
      }
    }
  }, [sourceAccount, isOpenLoop, destinationAccountOrRecipient]);

  useEffect(() => {
    if (!isOpenLoop && isDefined(amount) && currency && currency !== amount.currency) {
      setAmount(switchCurrency(amount, currency));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  useEffect(() => {
    if (configuration.countries) {
      const country = countriesArray.find((c) => c["alpha-2"] === configuration.countries?.[0]);
      if (country) {
        handleSetSelectedCountry(country);
      }
    }
  }, [configuration.countries]);

  useAsyncEffect(async () => {
    if (selectedPaymentNetwork?.productId) {
      const product = await getPaymentNetworkProduct(selectedPaymentNetwork.productId);
      onChangePaymentNetwork(product);
    }
  }, [selectedPaymentNetwork]);

  useEffect(() => {
    setCountryFromAccount(destinationAccountOrRecipient, handleSetSelectedCountry);
  }, [destinationAccountOrRecipient?.iban, destinationAccountOrRecipient?.bic]);

  const isStructuredAddressValid = useMemo(
    () =>
      !!creditorAddress.structuredAddress?.buildingNumber &&
      !!creditorAddress.structuredAddress?.streetName &&
      !!creditorAddress.structuredAddress?.postCode &&
      !!creditorAddress.structuredAddress?.townName,
    [creditorAddress],
  );

  const isAddressValid = useMemo(
    () =>
      !!creditorAddress.country &&
      (canDefineAddress
        ? isAddressUnstructured
          ? isAddressOptional
            ? true
            : !!creditorAddress.addressLines
          : isStructuredAddressValid
        : true),
    [creditorAddress, isAddressOptional, isStructuredAddressValid, canDefineAddress, isAddressUnstructured],
  );

  const canSubmit = useMemo(() => {
    const isAmountValid = !!amount && amount.value > 0;
    if (isOpenLoop) {
      return (
        isAmountValid &&
        isAddressValid &&
        !!sourceAccount &&
        !!destinationAccountOrRecipient &&
        !!selectedPaymentNetwork
      );
    }
    return isAmountValid;
  }, [sourceAccount, destinationAccountOrRecipient, amount, isOpenLoop, isAddressValid, selectedPaymentNetwork]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (isDefined(amount) && canSubmit) {
        submitAmount(
          isOpenLoop
            ? (destinationAccountOrRecipient as AccountOrRecipient)
            : {
                ...recipientData,
                phone: countries[countryCode].phoneCode + recipientData?.phone?.split(" ").join(""),
              },
          amount,
          accounts,
          description,
          isOpenLoop ? sourceAccount : undefined,
          selectedPaymentNetwork,
          transferMode ?? undefined,
          canDefineAddress ? creditorAddress : undefined,
          convertedAmount,
          canMakeRecurringTransfer ? recurringTransferParams : undefined,
        );
      }
    },
    [
      amount,
      selectedPaymentNetwork,
      transferMode,
      accounts,
      convertedAmount,
      creditorAddress,
      description,
      isOpenLoop,
      canDefineAddress,
      canMakeRecurringTransfer,
      canSubmit,
      recipientData,
      countryCode,
      sourceAccount,
      destinationAccountOrRecipient,
      recurringTransferParams,
      submitAmount,
    ],
  );

  const editPhone = (phoneNumber: string) => {
    const nationalNumber = "+" + countries[countryCode].phoneCode;
    const internationalPhoneNumber = phoneNumber.startsWith(nationalNumber)
      ? phoneNumber
      : nationalNumber + phoneNumber;
    const formatted = new AsYouType().input(internationalPhoneNumber).substr(nationalNumber.length);
    setRecipientData({ ...recipientData, phone: formatted });
  };

  const handleSetSelectedCountry = (country: Country) => {
    setSelectedCountry(country);
    setCreditorAddress({
      ...creditorAddress,
      country: country["iso-numeric"],
    });
  };

  const currencySelectOptions =
    paymentNetwork && sourceAccount ? getCurrencySelectOptions(paymentNetwork, sourceAccount) : [];

  useEffect(() => {
    if (amount && sourceAccount) {
      const accountBalance = getAvailableBalance(sourceAccount)?.value;
      if (accountBalance < amount.value) {
        setAmountError(formatMessage("amountSelection.insufficient_balance"));
      } else {
        setAmountError(null);
      }
    }
  }, [amount, sourceAccount, formatMessage]);

  return (
    <Container className={className} onSubmit={handleSubmit}>
      <LeftColumn>
        {isP2PSimpleTransfer ? (
          <>
            <FormLabel size="normal" margin="normal" label="amountSelection.simpleTransferNameLabel" />
            <TextInput
              value={recipient?.name || recipientData?.name}
              onChange={(event) => setRecipientData({ ...recipientData, name: event.target.value })}
              placeholder={formatMessage("amountSelection.simpleTransferNamePlaceholder")}
            />
            <FormLabel size="normal" margin="normal" label="amountSelection.simpleTransferPhoneLabel" />
            <CountrySelectInput
              onChange={(selectedCountryCode) => setCountryCode(selectedCountryCode)}
              innerId="country-select-field"
              options={configuration.countries}
              itemRenderer={(code) => countries[code].englishName}
              value={countryCode}
              prefixElement={countries[countryCode] && <FlagIcon src={countries[countryCode].flag} />}
            />
            <PhoneInput
              placeholder={formatMessage("amountSelection.simpleTransferPhonePlaceholder")}
              prefixElement={<PhoneCode>+{countries[countryCode].phoneCode}</PhoneCode>}
              type="tel"
              value={recipient?.phone || recipientData?.phone}
              onChange={(event) => {
                if (hasError) {
                  setDataError(undefined);
                }
                editPhone(event.target.value);
              }}
              hasError={hasError}
              errorMessage={formatMessage("common.invalidPhoneNumber")}
            />
          </>
        ) : isOpenLoop ? (
          <>
            {paymentNetworks.length > 1 ? (
              <>
                <FormLabel size="normal" margin="normal" label="amountSelection.network" required />
                <SelectInput
                  style={{ width: "100%" }}
                  innerId="payment-network"
                  required
                  options={paymentNetworks}
                  value={selectedPaymentNetwork}
                  onChange={(value) => setSelectedPaymentNetwork(value)}
                  itemRenderer={(v) => v?.name ?? ""}
                  hasError={hasError}
                />
              </>
            ) : null}
            <FormLabel size="normal" margin="normal" label="amountSelection.since" required />
            {sourceAccount ? (
              <AccountButton account={sourceAccount as AccountOrRecipient} onClick={onOpenSourceAccountSelection} />
            ) : (
              <SourceAndDestinationButton
                type="button"
                active={editingSourceAndDestination === SourceAndDestinationEnum.Source}
                onClick={onOpenSourceAccountSelection}
              >
                {formatMessage("amountSelection.choose_source_account")}
                <ChevronIcon width={18} height={18} style={{}} />
              </SourceAndDestinationButton>
            )}
          </>
        ) : null}
        {isOpenLoop ? (
          <>
            <FormLabel size="normal" margin="normal" label="amountSelection.to" required />
            {destinationAccountOrRecipient ? (
              <AccountButton
                account={destinationAccountOrRecipient}
                onClick={() =>
                  onOpenDestinationAccountOrRecipientSelection?.(
                    needCreditorPhoneNumber,
                    needCreditorAccountNumber,
                    canDefineAddress,
                  )
                }
              />
            ) : (
              <SourceAndDestinationButton
                type="button"
                active={editingSourceAndDestination === SourceAndDestinationEnum.Destination}
                onClick={() =>
                  onOpenDestinationAccountOrRecipientSelection?.(
                    needCreditorPhoneNumber,
                    needCreditorAccountNumber,
                    canDefineAddress,
                  )
                }
              >
                {formatMessage("amountSelection.choose_destination_account")}
                <ChevronIcon width={18} height={18} />
              </SourceAndDestinationButton>
            )}
            {forexEnabled && canShowExchangeRate && (
              <StyledForeignCurrencySelectInput
                value={foreignCurrency ? Currencies[foreignCurrency] : null}
                options={currencySelectOptions}
                placeholder={formatMessage("amountSelection.foreignCurrencyPlaceholder")}
                itemRenderer={(curr) => (curr ? `${(curr as Currency).name} (${(curr as Currency).symbol})` : "-")}
                innerId="foreign-currency"
                onChange={(curr) => setForeignCurrency((curr as Currency).iso_alpha)}
                disabled={!canEditForeignCurrency}
              />
            )}
          </>
        ) : (
          <>
            <FormLabel size="normal" margin="normal" label="amountSelection.currencyLabel" />
            <Selector
              options={Array.from(selectableCurrencies).map((curr) => ({
                value: curr,
                text: Currencies[curr].symbol,
                key: curr.toString(),
              }))}
              value={currency}
              onChange={(c) => setCurrency(c)}
              size="S"
            />
          </>
        )}
        <FormLabel size="normal" margin="normal" label="amountSelection.amountLabel" required />
        <AmountInput disabled={!currency} variant="big" value={amount} onChange={setAmount} />
        {forexEnabled && canShowExchangeRate && convertedAmount && (
          <StyledConvertedAmount>
            <span>{formatMessage("amountSelection.convertedAmountLabel")}</span>
            <AmountText amount={convertedAmount} />
          </StyledConvertedAmount>
        )}
        <FormLabel size="normal" margin="normal" label="amountSelection.descriptionLabel" />
        <TextInput value={description} onChange={(event) => setDescription(event.target.value)} />
        {isOpenLoop ? (
          <>
            <FormLabel
              size="normal"
              margin="normal"
              label={
                canDefineAddress
                  ? "amountSelection.creditorAddress.addressLabel"
                  : "amountSelection.creditorAddress.countryLabel"
              }
            />
            {canDefineAddress &&
              (isAddressUnstructured ? (
                <>
                  <StyledAddressLine>
                    <AddressTextInput
                      required={!isAddressOptional}
                      value={creditorAddress.addressLines}
                      onChange={(event) =>
                        setCreditorAddress({
                          ...creditorAddress,
                          addressLines: event.target.value,
                        })
                      }
                      placeholder={formatMessage("amountSelection.creditorAddress.addressLabel")}
                    />
                  </StyledAddressLine>
                </>
              ) : (
                <>
                  <StyledAddressLine>
                    <AddressTextInputSmall
                      required
                      value={creditorAddress.structuredAddress?.buildingNumber}
                      onChange={(event) =>
                        setCreditorAddress({
                          ...creditorAddress,
                          structuredAddress: {
                            ...creditorAddress.structuredAddress,
                            buildingNumber: event.target.value,
                          },
                        })
                      }
                      placeholder={formatMessage("amountSelection.creditorAddress.buildingNumber")}
                    />
                    <AddressTextInput
                      required
                      value={creditorAddress.structuredAddress?.streetName}
                      onChange={(event) =>
                        setCreditorAddress({
                          ...creditorAddress,
                          structuredAddress: {
                            ...creditorAddress.structuredAddress,
                            streetName: event.target.value,
                          },
                        })
                      }
                      placeholder={formatMessage("amountSelection.creditorAddress.streetName")}
                    />
                  </StyledAddressLine>
                  <StyledAddressLine>
                    <AddressTextInput
                      required
                      value={creditorAddress.structuredAddress?.postCode}
                      onChange={(event) =>
                        setCreditorAddress({
                          ...creditorAddress,
                          structuredAddress: {
                            ...creditorAddress.structuredAddress,
                            postCode: event.target.value,
                          },
                        })
                      }
                      placeholder={formatMessage("amountSelection.creditorAddress.postCode")}
                    />
                  </StyledAddressLine>
                  <StyledAddressLine>
                    <AddressTextInput
                      required
                      value={creditorAddress.structuredAddress?.townName}
                      onChange={(event) =>
                        setCreditorAddress({
                          ...creditorAddress,
                          structuredAddress: {
                            ...creditorAddress.structuredAddress,
                            townName: event.target.value,
                          },
                        })
                      }
                      placeholder={formatMessage("amountSelection.creditorAddress.townName")}
                    />
                  </StyledAddressLine>
                </>
              ))}
            <StyledAddressLine>
              <SelectInput
                required
                options={countriesArray}
                value={selectedCountry}
                itemRenderer={(v) => (v ? v.name : "-")}
                innerId="creditor-country"
                onChange={(country) => handleSetSelectedCountry(country)}
              />
            </StyledAddressLine>
          </>
        ) : (
          !acceptor &&
          !isP2PSimpleTransfer && <TransferModeSelection recipient={recipient} transferTypeSelected={setTransferMode} />
        )}
        {sourceAccount?.currencyAlpha === foreignCurrency && canMakeRecurringTransfer && (
          <RecurringTransferForm value={recurringTransferParams} onChange={setRecurringTransferParams} />
        )}
        {amountError && <StyledErrorMessage>{amountError}</StyledErrorMessage>}
        <ButtonContainer>
          <NextButton size="S" disabled={!canSubmit} showSpinner={loading}>
            <ButtonLabel>{formatMessage("amountSelection.sendButton")}</ButtonLabel>
          </NextButton>
        </ButtonContainer>
      </LeftColumn>
      {children ? <RightColumn>{children}</RightColumn> : null}
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 30px;
`;

const LeftColumn = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
const NextButton = styled(PrimaryButton)``;

const ButtonLabel = styled.span<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? "#b1b1b1" : "#ffffff")};
`;

const inputStyle = css`
  width: 100%;
  margin-bottom: 25px;
`;

const CountrySelectInput = styled<PrefixedSelectInputType<CountryCode>>(PrefixedSelectInput)`
  ${inputStyle};
`;

const FlagIcon = styled.img`
  width: 14px;
  height: 14px;
  border-radius: 7px;
`;

const PhoneInput = styled(PrefixedTextInput)`
  ${PrefixContainer} {
    width: 80px;
  }
`;

const PhoneCode = styled.span`
  font-size: 0.875rem;
`;

const SourceAndDestinationButton = styled("button")<{ disabled?: boolean; active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  appearance: none;
  border: none;
  height: 48px;
  box-shadow: none;
  background-color: white;
  text-align: left;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  ${theme.bodyBlackRegular};
  ${(props) =>
    props.active &&
    css`
      color: ${theme.mainColor()};
      svg {
        fill: ${theme.mainColor()};
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      :hover {
        cursor: not-allowed;
      }
    `}
`;

const StyledAddressLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 15px;
`;

const AddressTextInput = styled(TextInput)`
  flex: 1;
`;

const AddressTextInputSmall = styled(AddressTextInput)`
  max-width: 100px;
`;

const StyledConvertedAmount = styled.div`
  ${theme.bodySmallGreyDarkMedium};
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 12px;
  background-color: ${theme.colors.gray[300]};
  padding: 12px;
`;

const StyledForeignCurrencySelectInput = styled(SelectInput)`
  margin-top: 15px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0 5px;
`;
